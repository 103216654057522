import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const strategicAccountExecutive: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/97A8D07129/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'us') {
      setLocationData({
        roleLocation: 'New York, NY',
        link: 'https://apply.workable.com/lensesio/j/1738450B23/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Strategic Account Executive in London and New York. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Strategic Account Executive, London,New York,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/3X9DubgCmqGVhbIQDD9pMM/33c86b2a445cf409d36185f088398a26/strategic-account-executive.jpg',
      },
    },
    title: {
      title: 'Strategic Account Executive',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/careers/strategic-account-executive/"
        />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Strategic Account Executive</h1>
          <p className="meta">{roleLocation} </p>
          <section className="section section--text">
            <h5 className="pt-3 fw-600">Who we are?</h5>
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                You will work with new prospects and existing customers. You
                will use your sales skills to nurture, prospect, and close
                opportunities. You have a strong background of successfully
                selling software and services, and building strong relationships
                with customers.
              </li>
              <li>
                You will meet/exceed assigned revenue goals, for a specific
                territory, working with our team solutions architects, sales
                development reps and partner managers. You will follow up on
                leads, perform detailed discovery, use product demos for
                qualified customers and lead the technical evaluation process
                with thesupport of a solutions architect and sales engineer.
              </li>
              <li>
                Accurately forecast opportunities based upon realistic
                assessments, good qualification techniques and consistently
                deliver against that forecast.
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                Strong experience in strategic accounts and selling enterprise
                IT software solutions (preferably related to big data, data
                operations, or data management technologies)
              </li>
              <li>
                You have experience of selling complex opportunities into large
                organisations
              </li>
              <li>
                You will demonstrate experience in selling to C level executives
                and have experience of breaking into and managing a targeted
                territory
              </li>
              <li>
                Successful experience running the entire sales cycle from
                beginning to end and being responsible for achieving your own
                target
              </li>
              <li>
                Strong reputation of exceeding sales targets (you are in the top
                10%-20% on your sales team/organisation)
              </li>
              <li>
                Successful in finding and uncovering opportunities with new
                customers, using social media, research, creative prospecting
                tactics and hunting activities
              </li>
              <li>
                Highly motivated and professional, with excellent verbal,
                communication, presentation and social skills
              </li>
              <li>
                You have shown strong technical understanding and passionate
                about technology sales
              </li>
              <li>
                Excellent listening skills with the attested ability to
                prioritize and complete multiple tasks to meet deadlines
              </li>
              <li>
                Self-starter able to work independently but also a team player
              </li>
              <li>
                Willingness and desire to learn new things and ways to be more
                efficient and improve
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default strategicAccountExecutive
